import getSlugs from "./slugs.js";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 1,
        changefreq: "monthly"
      }
    },
    beforeEnter: (to, from, next) => {
        switch (window.location.host) {
          case 'ops.leighton.com':
            window.location.href = 'https://leighton.atlassian.net/'
            break;
          case 'helpdesk.leighton.com':
          case 'support.leighton.com':
            window.location.href = 'https://leighton.zendesk.com/'
            break;
          case 'pass.leighton.com':
            window.location.href = 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx'
            break;
          default:
            next()
        }
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services/Main.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/services/software-development",
    name: "Software Development",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "../views/Services/Software-Development.vue"
      ),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/services/team-augmentation",
    name: "Team Augmentation",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "../views/Services/Team-Augmentation.vue"
      ),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/services/virtual-events",
    name: "Virtual Events",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "../views/Services/Virtual-Events.vue"
      ),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/join-us/working-at-leighton",
    name: "Working at Leighton",
    component: () =>
      import(
        /* webpackChunkName: "ourofferings" */ "../views/JoinUs/WorkingAtLeighton.vue"
      ),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/join-us/current-vacancies",
    name: "Current Vacancies",
    component: () =>
      import(
        /* webpackChunkName: "currentvacancies" */ "../views/JoinUs/CurrentVacancies.vue"
      ),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/resources",
    alias: ["/blog"],
    name: "Resources",
    component: () =>
      import(/* webpackChunkName: "resources" */ "../views/Resources.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.8,
        changefreq: "weekly"
      }
    }
  },
  {
    path: "/get-in-touch",
    name: "Get In Touch",
    component: () =>
      import(/* webpackChunkName: "getintouch" */ "../views/GetInTouch.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 1,
        changefreq: "monthly"
      }
    }
  },
  {
    path: "/blog/:slug",
    name: "Blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
    meta: {
      sitemap: {
        lastmod: "2021-04-01",
        priority: 0.5,
        changefreq: "monthly",
        slugs: async () => await getSlugs()
      }
    }
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () =>
      import(
        /* webpackChunkName: "privacypolicy" */ "../views/PrivacyPolicy.vue"
      )
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: () =>
      import(/* webpackChunkName: "cookiepolicy" */ "../views/CookiePolicy.vue")
  },
  {
    path: "/verify-email",
    name: "Verify Email",
    component: () =>
      import(/* webpackChunkName: "verifyemail" */ "../views/VerifyEmail.vue")
  },
  {
    path: "*",
    name: "404 Not Found",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/Error404.vue")
  },
  //301 Redirects for old URLS
  { path: '/design-playground', redirect: '/' },
  { path: '/design-playground/*', redirect: '/' },
  { path: '/sign-up', redirect: '/' },
  { path: '/sign-up/*', redirect: '/' },
  { path: '/about-us', redirect: '/about' },
  { path: '/category/*', redirect: '/resources' },
  { path: '/what-we-do', redirect: '/services' },
  { path: '/what-we-do/customer-case-studies', redirect: '/services' },
  { path: '/what-we-do/customer-case-studies/*', redirect: '/services' },
  { path: '/what-we-do/what-we-do-custom-software', redirect: '/services/software-development' },
  { path: '/what-we-do/what-we-do-dedicated-teams', redirect: '/services/team-augmentation' },
  { path: '/what-we-do/augmented-teams', redirect: '/services/team-augmentation' },
  { path: '/about-us/join-us-values', redirect: '/join-us/working-at-leighton' },
  { path: '/about-us/join-us-leighton-life', redirect: '/join-us/working-at-leighton' },
  { path: '/about-us/vacancies', redirect: '/join-us/current-vacancies' },
  {
    path: '/onboarding',
    beforeEnter(to, from, next) {
      window.location.replace("https://wearehuman.hr")
    }
  },
  {
    path: '/onboarding/*',
    beforeEnter(to, from, next) {
      window.location.replace("https://wearehuman.hr")
    }
  },
  {
    path: '/employee-onboarding-software',
    beforeEnter(to, from, next) {
      window.location.replace("https://wearehuman.hr")
    }
  },
  {
    path: '/productsandplans',
    beforeEnter(to, from, next) {
      window.location.replace("https://wearehuman.hr")
    }
  },
  {
    path: '/onboarding-terms-and-conditions',
    beforeEnter(to, from, next) {
      window.location.replace("https://wearehuman.hr/terms")
    }
  }
];
