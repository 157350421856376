import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/vue2-google-maps";
import "./plugins/vue-social-sharing";
import "./plugins/vue-disqus";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

Vue.mixin({
  updated() {
    const context = (this as Vue);
    if (context.$route && context.$route.hash.length) {
      const refName = context.$route.hash.replace("#", "");
      const element = context.$children[0] && context.$children[0].$refs[refName] ? context.$children[0].$refs[refName] : context.$refs[refName];
      if (element) {
        const top = element instanceof HTMLElement ? element.offsetTop : 0;
        setTimeout(() => window.scrollTo(0, top), 500);
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
