import axios from "axios";

class ApiService {
  async get(url: string) {
    const res = await axios.get(url);
    return res.data;
  }

  async post(url: string, postData: object) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(url, postData, config);
    return res.data;
  }

  async put(url: string, putData: object) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    const res = await axios.put(url, putData, config);
    return res.data;
  }
}

export default new ApiService();
